import { useDispatch } from 'react-redux';

import { User } from 'generated/graphql';
import {
  featurePermissionFlagsLabels,
  permissionLabels,
} from 'javascript/reducers/permissionReducer';
/**
 * @returns setUserPermissions
 */

export const useUserPermissions = () => {
  const dispatch = useDispatch();

  const setUserQueryPermissions = (userFlags: Array<string>, user: User) => {
    const queryPermissions = [permissionLabels.read]; // user is by default

    // Refactor this later

    // if user is not read only, add write permission by default
    // work on this logic later
    if (!userFlags?.includes('read_only_user')) {
      queryPermissions.push(permissionLabels.write);
    }
    // if user is super admin, add write permission by default
    if (user?.isSuperAdmin && !queryPermissions.includes(permissionLabels.write)) {
      queryPermissions.push(permissionLabels.write);
    }

    dispatch({
      type: 'SET_USER_QUERY_PERMISSIONS',
      payload: queryPermissions,
    });
  };

  const setUserFeaturePermissions = (userFlags: Array<string>) => {
    // userflags is an array containing strings , now lets check if each of the required flag is present or not

    const featurePermissions: Record<string, boolean> = {};

    Object.values(featurePermissionFlagsLabels)?.forEach((flag) => {
      featurePermissions[flag] = userFlags.includes(flag);
    });

    dispatch({
      type: 'SET_USER_FEATURE_PERMISSIONS',
      payload: featurePermissions,
    });
  };

  const setUserPermissions = (userFlags: Array<string>, user: User) => {
    // set user query permissions
    setUserQueryPermissions(userFlags, user);

    // set user feature permissions
    setUserFeaturePermissions(userFlags);
  };

  return { setUserPermissions };
};

import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  Operation,
  Observable,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { LocalStorageWrapper, persistCache } from 'apollo3-cache-persist';
import { deleteProperty, setProperty } from 'dot-prop';
import produce from 'immer';
import * as React from 'react';

import { getAuthToken, logout } from 'features/auth';
import { Driver } from 'generated/graphql';
import { COMMONS } from 'globalConstants';
import { toast } from 'lib/toast';

import { store } from './store';

const { ERROR } = COMMONS;
const API_URL = process.env.REACT_APP_REMOTE_HOST + 'graphql';
const NETWORK_ERROR_TOAST_ID = 'network-error';

const httpLink = createHttpLink({
  uri: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Source: 'standaloneapp',
  },
});

const authLink = new ApolloLink((operation, forward) => {
  const token = getAuthToken();

  operation.setContext(
    produce((context: Record<string, unknown>) => {
      if (token) {
        setProperty(context, 'headers.Authorization', `Bearer ${token}`);
      } else {
        deleteProperty(context, 'headers.Authorization');
      }
    })
  );

  return forward(operation);
});

const errorLink = onError(({ networkError }) => {
  if (!networkError || !('result' in networkError) || !networkError.result?.data?.error_code) {
    // Network error(500)
    // TODO: Send log to sentry for 500
    return;
  }

  const showErrorAndLogout = (title: string, description: string) => {
    if (!toast.isActive(NETWORK_ERROR_TOAST_ID)) {
      toast({
        id: NETWORK_ERROR_TOAST_ID,
        title,
        description,
        status: ERROR,
        position: 'top-right',
        isClosable: true,
      });
    }

    if (location.pathname !== '/auth/login') {
      logout(location.href.replace(location.origin, ''));
    }
  };

  switch (networkError.result.data.error_code) {
    case 1014:
      showErrorAndLogout(
        'Expired credentials',
        'Your authentication token has expired. Please log in again.'
      );
      break;
    case 1003:
      showErrorAndLogout(
        'Invalid credentials',
        'Your authentication token is invalid. Please log in again.'
      );
      break;
  }
});

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        filteredDrivers: {
          read(existing) {
            return existing?.collection ? existing : {};
          },
          keyArgs: ['filter'],
          merge(existing, incoming, { args }) {
            const offset = args?.offset || 0;
            const hasIncColl = incoming?.collection.length ? incoming?.collection : [];
            const mergedColl = existing?.collection?.length
              ? [...existing.collection]?.splice(0)
              : [];
            hasIncColl?.forEach((indvCol: Driver, index: number) => {
              mergedColl[offset + index] = indvCol;
            });
            const mergedDriverList = { ...incoming, collection: mergedColl };
            return mergedDriverList;
          },
        },
      },
    },
  },
});

persistCache({
  cache,
  storage: new LocalStorageWrapper(localStorage),
});

// Function to run before every API call
const runBeforeEachRequest = (operation: Operation) => {
  /**
   * Run the following queries without any permission
   * 1. fetchCurrent
   * 2. signInUser
   */
  if (operation?.operationName === 'fetchCurrent') {
    return true;
  } else if (operation?.operationName === 'UserSignIn') {
    return true;
  }

  // if mutation, check if user has permission to write
  if (operation?.query?.loc?.source?.body?.includes('mutation')) {
    const userPermissions = store.getState().userPermissions;
    const allowMutation = userPermissions?.queryPermissions?.includes('write');
    return allowMutation;
  }

  // return true for all other queries
  return true;
};

// Custom Apollo Link to run your function before each request
const beforeLink = new ApolloLink((operation, forward) => {
  const allowFurther = runBeforeEachRequest(operation);

  if (!allowFurther) {
    // throw a toast
    toast({
      title: 'Permission Denied',
      description: 'You do not have permission to perform this action',
      status: 'warning',
      duration: 5000,
      isClosable: true,
    });
    // fake the query response and complete it anyway
    return new Observable((observer) => {
      observer.next({ data: null });
      // complete query throw error

      observer.complete();
    });
  }
  return forward(operation); // Continue with the API request
});

// Combine the links
const link = ApolloLink.from([beforeLink, authLink, errorLink, httpLink]);

export const apolloClient = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
    },
    mutate: {
      fetchPolicy: 'network-only',
    },
  },
});

export const ApolloWrapper = ({
  children,
}: {
  children: React.ReactNode | React.ReactNode[] | null;
}) => {
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

/**
 * DEPRECATED: Please consult with the FE Performance team before using this file.
 */
import { getAuthToken, logout } from 'features/auth';
// import Cookies from 'js-cookie';
import { errorCodes } from 'globalConstants/errorCodes';
import { createStandaloneToast } from '@chakra-ui/react';
import { GraphApiErr } from 'error/GraphApiErr';
import { toast } from 'lib/toast';
import store from '../javascript/store';
// const AUTH_TOKEN = Cookies.get('fp_access_token');

const NETWORK_ERROR_TOAST_ID = 'network-error';
const API_URL = `${process.env.REACT_APP_REMOTE_HOST}graphql`;
const LOGOUT_ERROR_CODES = [
  errorCodes.ACCESS_TOKEN_NOT_FOUND_IN_DB,
  errorCodes.TRIED_TO_USE_TOKEN_AFTER_LOGOUT,
];

const getHeaders = () => ({
  'Content-Type': 'application/json',
  Accept: 'application/json',
  'X-CSRF-Token': window.csrfToken,
  Source: 'standaloneapp',
});

// Function to run before every API call
const runBeforeEachRequest = (query) => {
  // return true for following queries

  let tempQuery = '';
  // if the type of query is string, then leave it else if object then convert to string
  if (typeof query === 'string') {
    tempQuery = query;
  } else {
    tempQuery = JSON.stringify(query);
  }

  /**
   * Run the following queries without any permission
   * 1. fetchCurrent
   * 2. signInUser
   */
  if (tempQuery.includes('fetchCurrent')) {
    return true;
  } else if (tempQuery.includes('signInUser')) {
    return true;
  }

  // if query is mutation then check if user has permission to write
  if (tempQuery.includes('mutation')) {
    const userPermissions = store.getState().userPermissions;
    const allowMutation = userPermissions?.queryPermissions?.includes('write');
    return allowMutation;
  }

  // return true for all other conditions
  return true;
};

export function fetchGraphQLData(query) {
  //
  // if runBeforeEachRequest returns false then return empty object
  if (!runBeforeEachRequest(query)) {
    // throw a toast
    toast({
      title: 'No Permission',
      description: 'You do not have permission to perform this action',
      status: 'error',
      position: 'top-right',
      isClosable: true,
    });
    return Promise.resolve({});
  }

  const controller = new AbortController();

  if (typeof query === 'string') {
    // Keep backwards compatibility with old string queries
    query = JSON.parse(query);
  }

  const payload = {
    ...query,
    query: typeof query.query === 'string' ? query.query : query.query,
  };

  const headers = getHeaders();
  const token = getAuthToken();

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const promise = fetch(API_URL, {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
    signal: controller.signal,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data?.error || data?.errors) {
        console.error('>>> GraphQL data Error\n\n', data.error, data.errors?.[0]);
        const tempError = data.errors?.[0]
          ? { message: data.errors[0]?.message, code: data.errors[0]?.extensions?.code }
          : { message: data.error, code: data.status };
        throw new GraphApiErr(tempError);
      }

      // TODO: refactor later, success and errorCode may not exit
      if (!data.success && data.errorCode) {
        if (!toast.isActive(NETWORK_ERROR_TOAST_ID)) {
          toast({
            id: NETWORK_ERROR_TOAST_ID,
            title: 'Logged Out',
            description: 'Token Expired',
            status: 'error',
            position: 'top-right',
            isClosable: true,
          });
        }
        if (LOGOUT_ERROR_CODES.includes(data.errorCode)) {
          logout(location.href.replace(location.origin, ''));
        }
      }
      return data;
    })
    .catch((error) => {
      // if (error?.message?.includes('aborted')) {
      //   console.warn('>>> GraphQL request aborted');
      //   return Promise.resolve({ aborted: true });
      // }

      // console.error('>>> GraphQL Error\n\n', error);
      return Promise.reject(error);
    });

  promise.abort = () => controller.abort();

  return promise;
}

export async function fetchGraphQLDataMutation(query) {
  //
  // if runBeforeEachRequest returns false then return empty object
  if (!runBeforeEachRequest(query)) {
    // throw a toast
    toast({
      title: 'No Permission',
      description: 'You do not have permission to perform this action',
      status: 'error',
      position: 'top-right',
      isClosable: true,
    });
    return Promise.resolve({});
  }

  const headers = getHeaders();
  const token = getAuthToken();

  if (token) {
    headers['Authorization'] = `Bearer ${token}`;
  }

  const response = await fetch(API_URL, {
    method: 'POST',
    headers,
    body: query,
  }).catch(handleError);

  const data = await response.json();

  if (data.error) {
    console.error('>>> GraphQL Error\n\n', data.error);
  }

  if (!data.success && data.errorCode) {
    if (!toast.isActive(NETWORK_ERROR_TOAST_ID)) {
      toast({
        id: NETWORK_ERROR_TOAST_ID,
        title: 'Logged Out',
        description: 'Token Expired',
        status: 'error',
        position: 'top-right',
        isClosable: true,
      });
    }
    if (LOGOUT_ERROR_CODES.includes(data.errorCode)) {
      logout(location.href.replace(location.origin, ''));
    }
  }

  return data;
}

var handleError = function (err) {
  console.error('>>> handleError GraphQL Error\n\n', err);
  return JSON.stringify({
    code: 400,
    message: 'Stupid network Error',
  });
};
